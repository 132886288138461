import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../Common';
import { Container } from 'reactstrap';
import cogoToast from 'cogo-toast';

import { Constant } from '../../Helpers/constant';
import Table from './Components/Tables/Table';
import { DOWNLOADS_LIST, DOWNLOAD_FILE, DOWNLOADS_LIST_EMPTY, DOWNLOAD_LIST_V2, DOWNLOAD_LIST_V2_EMPTY } from '../../../store/application/actionType';

const Downloads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector(state => ({
    downloads: state?.DownloadsList?.data,
    downloadsLoading: state?.DownloadsList?.loading,
  }));

  const { downloadsV2, downloadsLoadingV2 } = useSelector(state => ({
    downloadsV2: state?.DwonloadListV2?.data,
    downloadsLoadingV2: state?.DwonloadListV2?.loading,
  }));

  console.log('downloadsV2', downloadsV2);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    console.log(
      downloads === null && !['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE),
      downloadsV2 === null && ['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE),
      ['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE),
      downloadsV2,
    );
    if (downloads === null && !['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE)) {
      dispatch({
        type: DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    } else if (downloadsV2 === null && ['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE)) {
      dispatch({
        type: DOWNLOAD_LIST_V2,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE)) {
        dispatch({
          type: DOWNLOAD_LIST_V2_EMPTY,
        });
      } else {
        dispatch({
          type: DOWNLOADS_LIST_EMPTY,
        });
      }
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    if (['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE)) {
      dispatch({
        type: DOWNLOAD_LIST_V2,
        payload: {
          urlParam: {
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    } else {
      dispatch({
        type: DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    if (['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE)) {
      dispatch({
        type: DOWNLOAD_LIST_V2,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    } else {
      dispatch({
        type: DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading || downloadsLoadingV2) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading, downloadsLoadingV2]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Downloads`]} />
          <Table
            data={['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE) ? downloadsV2?.results : downloads?.results}
            totalData={['HSBC_BEACH_CLUB', 'THRIWE_APP'].includes(Constant.PROJECT_CODE) ? downloadsV2?.total_count : downloads?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default Downloads;
